<template>
  <div id="app">
    <Index/>
  </div>
</template>

<script>
import Index from './components/Index.vue'

export default {
  name: 'app',
  components: {
    Index
  }
}
</script>

<style>
@import url("//cdnjs.cloudflare.com/ajax/libs/remodal/1.1.0/remodal.css");
@import url("//cdnjs.cloudflare.com/ajax/libs/remodal/1.1.0/remodal-default-theme.min.css");
@import './assets/css/style.css';

</style>
