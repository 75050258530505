export default {
  title: "翁生的简历",
  basicInfo: "基础信息",
  name: "姓名",
  myName: "翁生",
  degree: "学历",
  myDegree: "本科",
  major: "专业",
  myMajor: "通信工程",
  school: "毕业院校",
  mySchool: "湖南工学院",
  education: "教育经历",
  graduateTime: "毕业年份",
  myGraduateTime: "2017",
  contact: "联系方式",
  mobile: "手机",
  myMobile: "(86) 132-7671-1862",
  email: "邮箱",
  myEmail: "raighne.weng@gmail.com",
  page: "个人主页",
  myPage: "www.raighne.xyz",
  skills: "技能点",
  skillMap: "技术栈",
  devOps: "DevOps",
  backEnd: "后端",
  other: "其他",
  embedded: "TensorFlow",
  college: "湖南工学院 - 通信工程 2013-2017",
  lang: "英语四级",
  experience: "工作经验",
  projects: "项目经验",
  selfAssessment: "自我评价",
  mySelfAssessment: "我是一个崇尚工程师文化、热爱计算机、喜欢钻研的IT工程师。多年的工作经验，让我认识到了自我的渺小，站在人类巨灵的肩膀上 Stay hungry. Stay foolish. 我目前正在寻找后端工程师岗位，希望借此机会为贵司献上我的一点绵薄之力~",
};
